import React, { useEffect, useState } from 'react';
import './Team.css';
import teamData from './teamData'; 

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Team = () => {
  const [shuffledTeam, setShuffledTeam] = useState([]);

  useEffect(() => {
    // Shuffle team data and set it to state
    setShuffledTeam(shuffleArray([...teamData]));
  }, []); // Empty dependency array to shuffle only on mount

  return (
    <div className="team">
      <h2>leadership</h2>
      <div className="team-grid">
        {shuffledTeam.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.photo} alt={member.name} />
            <div className="member-info">
              <p className="member-name">
                {member.name.split(' ')[0]}<br />{member.name.split(' ')[1]}
              </p>
              <p className="member-title">{member.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
